var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "basic-container",
    [
      _c(
        "head-layout",
        _vm._g(
          {
            attrs: {
              "head-btn-options": _vm.headBtnOptions,
              "head-title": _vm.$t(
                "datacenter.dataQuality.monitoringRecord.title"
              ),
            },
          },
          _vm.onHeadEvent
        )
      ),
      _c(
        "grid-head-layout",
        _vm._g(
          {
            ref: "searchFrom",
            attrs: { "search-columns": _vm.searchColumns },
            model: {
              value: _vm.searchForm,
              callback: function ($$v) {
                _vm.searchForm = $$v
              },
              expression: "searchForm",
            },
          },
          _vm.onSearchEvent
        )
      ),
      _c(
        "grid-layout",
        _vm._g(_vm._b({}, "grid-layout", _vm.bindVal, false), _vm.onEvent)
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t(
              "datacenter.dataQuality.monitoringRecord.executionDetails"
            ),
            width: "80%",
            visible: _vm.detailDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { attrs: { border: "", data: _vm.detailTableData } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "ruleName",
                  label: _vm.$t(
                    "datacenter.dataQuality.monitoringRecord.ruleName"
                  ),
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "dataObjectLabel",
                  label: _vm.$t(
                    "datacenter.dataQuality.monitoringRecord.dataObjectLabel"
                  ),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "judgMethodName",
                  label: _vm.$t(
                    "datacenter.dataQuality.monitoringRecord.judgMethodName"
                  ),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "details",
                  label: _vm.$t(
                    "datacenter.dataQuality.monitoringRecord.executeResult"
                  ),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          { attrs: { trigger: "click", placement: "left" } },
                          [
                            _c(
                              "el-table",
                              {
                                attrs: { border: "", data: scope.row.details },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "status",
                                    label: _vm.$t(
                                      "datacenter.dataQuality.status"
                                    ),
                                    width: "200",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "name-wrapper",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { type: "text", size: "small" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("cip.cmn.btn.viewBtn"))
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "executeStatus",
                  label: _vm.$t(
                    "datacenter.dataQuality.monitoringRecord.executeStatus"
                  ),
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "originalDataDetail",
                  label: _vm.$t(
                    "datacenter.dataQuality.monitoringRecord.originalDataDetail"
                  ),
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          { attrs: { trigger: "click", placement: "left" } },
                          [
                            _c(
                              "el-table",
                              {
                                attrs: {
                                  border: "",
                                  data: scope.row.originalDataDetail,
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "id",
                                    label: "ID",
                                    width: "200",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "status",
                                    label: _vm.$t(
                                      "datacenter.dataQuality.status"
                                    ),
                                    width: "200",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "name-wrapper",
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { type: "text", size: "small" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("cip.cmn.btn.viewBtn"))
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
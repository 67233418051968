<template>
    <basic-container>
        <head-layout :head-btn-options="headBtnOptions"
                   :head-title="$t('datacenter.dataQuality.monitoringRecord.title')"
                   v-on="onHeadEvent">
        </head-layout>
        <grid-head-layout ref="searchFrom"
                        :search-columns="searchColumns"
                        v-model="searchForm"
                        v-on="onSearchEvent">
        </grid-head-layout>
        <grid-layout v-bind="bindVal"
                   v-on="onEvent">
        </grid-layout>
        <el-dialog
        :title="$t('datacenter.dataQuality.monitoringRecord.executionDetails')"
        width="80%"
        :visible.sync="detailDialogVisible">
            <el-table border :data="detailTableData">
                <el-table-column prop="ruleName" :label="$t('datacenter.dataQuality.monitoringRecord.ruleName')" width="150">
                </el-table-column>
                <el-table-column prop="dataObjectLabel" :label="$t('datacenter.dataQuality.monitoringRecord.dataObjectLabel')">
                </el-table-column>
                <el-table-column prop="judgMethodName" :label="$t('datacenter.dataQuality.monitoringRecord.judgMethodName')">
                </el-table-column>
                <el-table-column prop="details" :label="$t('datacenter.dataQuality.monitoringRecord.executeResult')">
                    <template slot-scope="scope">
                      <el-popover trigger="click" placement="left">
                        <el-table border :data="scope.row.details">
                          <el-table-column prop="status" :label="$t('datacenter.dataQuality.status')" width="200">
                          </el-table-column>
                        </el-table>
                        <div slot="reference" class="name-wrapper">
                          <el-button
                            type="text"
                            size="small">{{$t('cip.cmn.btn.viewBtn')}}</el-button>
                        </div>
                      </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="executeStatus" :label="$t('datacenter.dataQuality.monitoringRecord.executeStatus')">
                </el-table-column>
                <el-table-column prop="originalDataDetail" :label="$t('datacenter.dataQuality.monitoringRecord.originalDataDetail')">
                    <template slot-scope="scope">
                      <el-popover trigger="click" placement="left">
                        <el-table border :data="scope.row.originalDataDetail">
                          <el-table-column prop="id" label="ID" width="200">
                          </el-table-column>
                          <el-table-column prop="status" :label="$t('datacenter.dataQuality.status')" width="200">
                          </el-table-column>
                        </el-table>
                        <div slot="reference" class="name-wrapper">
                          <el-button
                            type="text"
                            size="small">{{$t('cip.cmn.btn.viewBtn')}}</el-button>
                        </div>
                      </el-popover>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </basic-container>
  </template>

  <script>
  import GridLayout from "@/views/components/layout/grid-layout";

  export default window.$crudCommon({
    data () {
      return {
        detailDialogVisible:false,
        detailTableData:[],
        headBtnOptions: [],
        gridRowBtn: [{
          label: this.$t('cip.cmn.btn.viewBtn'),
          emit: "grid-view",
          type: "text"
        }, ]
      }
    },
    components:{
    GridLayout
  },
    methods:{
        rowView(row,index){
            this.detailTableData = row.executeResultDetail;
            this.detailDialogVisible = true;
        }
    }
  }, {
    //模块路径
    name: 'dataCenter/programManageRecord',
  })
  </script>

  <style>
  </style>
